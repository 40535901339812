import React from 'react';
import { COLORS } from '../../styles';

const Logo: React.FC = () => {
  return (
    <svg id="logo" viewBox="0 0 456.51 71.65">
      <path
        style={{ fill: COLORS.PRIMARY_GREEN }}
        d="M535,261.24H497.12v1.84l-1.84-1.84H457.39v18.18l-4.61-18.18h-23.6l-3.62,13.43v-.39l-13-13H387.67l-7.41,7.44-7.4-7.44H335v12.68l-12.62-12.68H297.44l-1.84,1.84v-1.84H268.13v1.84l-1.83-1.84H228.41v71.65h37.88l1.84-1.85v1.85H295.6V331l1.85,1.85h24.89L335,320.26v12.63h37.88l7.41-7.42,7.42,7.42H438l2.5-9.3h.17l2.38,9.3h92l13-13V274.28Zm-95.31,26.3,1.14-4.22,1.08,4.24Z"
        transform="translate(-171.74 -261.24)"
      />
      <polygon points="126.53 2 121.86 6.69 121.86 2 98.39 2 98.39 6.69 93.73 2 58.67 2 58.67 69.65 93.72 69.65 98.39 64.97 98.39 69.65 121.86 69.65 121.86 64.97 126.54 69.65 149.78 69.65 161.59 57.83 161.59 13.86 149.78 2 126.53 2" />
      <path
        d="M534.18,263.24H499.12v4.69l-4.67-4.69H459.39v32.2l-8.17-32.2H430.71l-7.15,26.54V275.1l-11.81-11.86H388.5l-8.24,8.28L372,263.24H337v67.65h35l8.24-8.25,8.25,8.25h23.24l1-1-.28,1h23.94l2.5-9.3h3.25l2.39,9.3h89.61L546,319.07v-44ZM437.5,288l3.17-11.79h.44l3,11.84Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.WHITE }}
        d="M234.91,267.74v58.65h28.68l9.18-9.18V299.09l-2-1.95,2-2V277l-9.17-9.22Zm23.43,36.54v7.64h-9v-7.64Zm-9-14.43v-7.64h8.93v7.64Z"
        transform="translate(-171.74 -261.24)"
      />
      <rect
        style={{ fill: COLORS.WHITE }}
        x="102.9"
        y="6.5"
        width="14.47"
        height="58.65"
      />
      <path
        style={{ fill: COLORS.WHITE }}
        d="M300.14,267.74,291,277v40.25l9.18,9.18h19.51l9.18-9.18V277l-9.18-9.22Zm14.26,36.54v7.64h-9V282.21h9v7.64h-6v14.43Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.WHITE }}
        d="M341.47,267.74v58.65h28.69l9.17-9.18V299.09l-2-1.95,2-2V277l-9.17-9.22Zm23.44,36.54v7.64h-9v-7.64Zm-9-14.43v-7.64h8.93v7.64Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.WHITE }}
        d="M419.06,277l-9.18-9.22H390.37L381.19,277v40.25l9.18,9.18h19.51l9.18-9.18Zm-14.43,5.25v29.71h-9V282.21Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.WHITE }}
        d="M434.16,267.74l-15.8,58.65H433l2.5-9.3h10.2l2.38,9.3H462.6l-14.88-58.65ZM442,302.62h-2.63l1.35-5Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.WHITE }}
        d="M463.89,267.74v58.65h14.47V305.61h9v20.78h14.43v-27.3l-2-1.95,2-2V277l-9.18-9.22Zm23.44,14.47v9h-9v-9Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.WHITE }}
        d="M532.31,267.74H503.62v58.65H532.3l9.18-9.18V277Zm-5.26,14.47v29.71h-9V282.21Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.PRIMARY_GREEN }}
        d="M262.76,269.74l8,8.05v16.52l-2.85,2.85,2.85,2.77v16.45l-8,8H236.91V269.74Zm-15.39,22.11H260.3V280.21H247.37Zm13,10.43h-13v11.64h13Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.PRIMARY_GREEN }}
        d="M276.63,269.74H287.1v54.65H276.63Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.PRIMARY_GREEN }}
        d="M318.82,269.74l8,8.05v10.58H316.4v-8.16h-13v33.71h13V302.28h-6V291.85h16.45v24.53l-8,8H301l-8-8V277.79l8-8.05Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.PRIMARY_GREEN }}
        d="M369.33,269.74l8,8.05v16.52l-2.85,2.85,2.85,2.77v16.45l-8,8H343.47V269.74Zm-15.39,22.11h12.93V280.21H353.94Zm13,10.43h-13v11.64h13Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.PRIMARY_GREEN }}
        d="M409.05,269.74l8,8.05v38.59l-8,8H391.2l-8-8V277.79l8-8.05Zm-2.42,10.47h-13v33.71h13Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.PRIMARY_GREEN }}
        d="M431.44,324.39H421l14.72-54.65h10.47L460,324.39H449.6l-2.38-9.3H433.94Zm9.33-34.69-4,14.92h7.81Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.PRIMARY_GREEN }}
        d="M491.75,269.74l8,8.05v16.52l-2.85,2.85,2.85,2.77v24.46H489.33V303.61h-13v20.78H465.89V269.74Zm-2.42,10.47h-13v13h13Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.PRIMARY_GREEN }}
        d="M531.48,269.74l8,8.05v38.59l-8,8H505.62V269.74Zm-2.43,10.47h-13v33.71h13Z"
        transform="translate(-171.74 -261.24)"
      />
      <polygon
        style={{ fill: COLORS.PRIMARY_GREEN }}
        points="60.5 40.88 60.5 31.12 42.01 31.12 41.23 31.12 32.25 31.12 32.25 43.28 29.85 43.28 29.85 31.12 20.86 31.12 20.09 31.12 0 31.12 0 40.88 5.54 40.88 5.54 65.17 15.3 65.17 15.3 40.88 20.09 40.88 20.09 65.17 29.85 65.17 29.85 53.01 32.25 53.01 32.25 65.17 41.23 65.17 42.01 65.17 60.5 65.17 60.5 55.41 50.99 55.41 50.99 53.01 58.59 53.01 58.59 43.28 50.99 43.28 50.99 40.88 60.5 40.88"
      />
      <polygon points="1 39.88 6.54 39.88 6.54 64.17 14.3 64.17 14.3 39.88 19.86 39.88 19.86 32.12 1 32.12 1 39.88" />
      <polygon points="33.25 44.28 28.85 44.28 28.85 32.12 21.09 32.12 21.09 64.17 28.85 64.17 28.85 52.01 33.25 52.01 33.25 64.17 41.01 64.17 41.01 32.12 33.25 32.12 33.25 44.28" />
      <polygon points="59.5 39.88 59.5 32.12 42.23 32.12 42.23 64.17 59.5 64.17 59.5 56.41 49.99 56.41 49.99 52.01 57.59 52.01 57.59 44.28 49.99 44.28 49.99 39.88 59.5 39.88" />
      <path
        style={{ fill: COLORS.WHITE }}
        d="M190.6,294.36v5.75H185v24.3h-5.76v-24.3h-5.54v-5.75Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.WHITE }}
        d="M206,294.36h5.76v30.05H206V312.25h-6.4v12.16h-5.76V294.36h5.76v12.16H206Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.WHITE }}
        d="M230.24,294.36v5.75h-9.52v6.41h7.61v5.73h-7.61v6.4h9.52v5.76H215V294.36Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.PRIMARY_GREEN }}
        d="M628.24,298l-5.57-5.6H611.19l-4.8,4.81v-4.81h-9.75v9.69l-4.31-9.69h-9.77V321.1l-7.29-28.74h-8.85l-5,18.46v-6.3h-7.61v-2.41h9.52v-9.75H544.08v34.05h9.76V314.25h6.68l-3.27,12.16h9.9l1.37-5.11h4.22l1.31,5.11h18.27v-9.69l4.31,9.69h9.76v-4.8l4.8,4.8h11.48l5.57-5.57V310.12l-.72-.7,0,0-2.86-2.8h3.56Zm-12.87,16.29h3.14v2.4h-3.14Zm3.14-9.73h-3.14v-2.41h3.14Zm-12.12,5,2.64,2.68h-2.64Z"
        transform="translate(-171.74 -261.24)"
      />
      <polygon points="388.71 44.28 381.11 44.28 381.11 39.88 390.62 39.88 390.62 32.12 373.35 32.12 373.35 64.17 381.11 64.17 381.11 52.01 388.71 52.01 388.71 44.28" />
      <path
        d="M567.19,293.36l-8.64,32.05h7.83l1.38-5.11h5.76l1.31,5.11h7.8l-8.14-32.05Zm4.36,19.18h-1.71l.88-3.26Z"
        transform="translate(-171.74 -261.24)"
      />
      <polygon points="425.9 45.52 419.94 32.12 411.83 32.12 411.83 64.17 419.58 64.17 419.58 50.77 425.54 64.17 433.66 64.17 433.66 32.12 425.9 32.12 425.9 45.52" />
      <path
        d="M627.24,298.37l-5-5H611.6l-5,5v9.91l4.79,4.88h-4.79v7.26l5,5h10.64l5-5v-9.88l-1.14-1.11,0,0-3.89-3.81h5Zm-12.87,14.88h5.14v4.4h-5.14Zm5.14-7.73h-5.14v-4.41h5.14Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.WHITE }}
        d="M561.36,294.36v5.75h-9.52v6.41h7.61v5.73h-7.61v12.16h-5.76V294.36Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.WHITE }}
        d="M565.61,324.41h-5.75l8.1-30.05h5.75l7.63,30.05H575.6l-1.31-5.11H567Zm5.14-19.08-2.21,8.21h4.29Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.WHITE }}
        d="M598.64,294.36h5.75v30.05h-6.46l-7.61-17.12v17.12h-5.76V294.36H591l7.61,17.12Z"
        transform="translate(-171.74 -261.24)"
      />
      <path
        style={{ fill: COLORS.WHITE }}
        d="M621.84,294.36l4.4,4.42v5.82h-5.73v-4.49h-7.14v6.41h8.36l3,2.9,0,0,1.57,1.52V320l-4.4,4.4H612l-4.4-4.4v-5.85h5.75v4.49h7.14v-6.4h-8.6l-4.29-4.38v-9.09l4.4-4.42Z"
        transform="translate(-171.74 -261.24)"
      />
      <circle
        style={{ fill: COLORS.PRIMARY_GREEN }}
        cx="368.29"
        cy="63.57"
        r="3.59"
      />
      <circle cx="368.29" cy="63.57" r="3.09" />
      <circle style={{ fill: COLORS.WHITE }} cx="368.29" cy="63.57" r="2.59" />
    </svg>
  );
};

export default Logo;
